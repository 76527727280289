.o-container {
  margin-left: auto;
  margin-right: auto;
  max-width: var(
    --container-max-width,
    calc(var(--container-content-max-width) + 2 * var(--container-padding-inline))
  );
  padding-left: var(--container-padding-inline);
  padding-right: var(--container-padding-inline);
  width: 100%;
}

.o-container--xl {
  --container-content-max-width: var(--container-xl-content-max-width);
}

.o-container--lg {
  --container-content-max-width: var(--container-lg-content-max-width);
}

.o-container--md {
  --container-content-max-width: var(--container-md-content-max-width);
}
