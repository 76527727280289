@tailwind base;
@tailwind components;
@tailwind utilities;

@import './container.css';

:root {
  --container-padding-inline: 24px;
  --container-content-max-width: 1880px; /* 1920px - 2 * 20px */
  --container-xl-content-max-width: 1130px; /* 1440px - 2 * 155px */
  --container-lg-content-max-width: 1016px; /* 1440px - 2 * 212px */
  --container-md-content-max-width: 670px; /* 1440px - 2 * 385px */
  --navbar-height: 84px;

  --gray-200: theme(colors.gray.200);

  @screen lg {
    --container-padding-inline: 0px;
  }
}

input:placeholder-shown {
  text-overflow: ellipsis;
}

main > section:first-child:not(.hero-masthead) {
  padding-top: var(--navbar-height);
}

.react-tweet-theme {
  height: 100%;
  margin: 0 auto !important;
}

nav {
  height: var(--navbar-height);
}

.gm-svpc,
.gm-fullscreen-control {
  border-radius: 6px !important;
  border: 1px solid #e6e8ea !important;
  --tw-shadow: 0px 56px 45px 0px rgba(33, 34, 56, 0.04) !important;
  --tw-shadow-colored: 0px 56px 45px 0px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow) !important;
}

.gm-svpc img,
.gm-fullscreen-control img {
  padding: 4px;
}

.crypto-table table {
  border-collapse: separate;
  border-spacing: 0;
}

.crypto-table tr:first-child td:first-child {
  border-top-left-radius: 10px;
}

.crypto-table tr:first-child td:last-child {
  border-top-right-radius: 10px;
}

.crypto-table tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

.crypto-table tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.wysiwyg strong,
.wysiwyg b {
  @apply text-h9 font-semibold text-gray-600;
}

.wysiwyg * {
  @apply pb-3 lg:pb-4;
}

.wysiwyg {
  @apply text-menu font-medium text-gray-400;
}

.wysiwyg a {
  @apply hover:opacity-50;
}

.wysiwyg.footerCopy a {
  text-decoration: underline;
}

.guides-wysiwyg strong,
.guides-wysiwyg b {
  @apply text-h9 font-semibold text-gray-600;
}

.guides-wysiwyg * {
  @apply pb-4;
}

.guides-wysiwyg :last-child {
  padding-bottom: 0;
}

.guides-wysiwyg ol {
  list-style-type: decimal;
  padding-left: 1.5rem;
}

.guides-wysiwyg ul {
  list-style-type: disc;
  padding-left: 1.5rem;
  @apply mb-3;
}

.guides-wysiwyg {
  @apply text-body-7 text-gray-400;
}

.guides-wysiwyg a {
  @apply underline hover:opacity-50;
}

.guides-wysiwyg img {
  border-radius: 16px;
  padding-bottom: 0;
  margin-bottom: 16px;
}

.guides-wysiwyg div[data-oembed-type] {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  border-radius: 16px;
  padding-bottom: 0;
  margin-bottom: 16px;
}

.guides-wysiwyg div[data-oembed-type] iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.rich-text ol {
  list-style-type: decimal;
  padding-left: 1.5rem;
  @apply mb-3;
}

.rich-text ul {
  list-style-type: disc;
  padding-left: 1.5rem;
  @apply mb-3;
}

.rich-text p {
  @apply mb-3 child:last:mb-0;
}

.rich-text a {
  @apply text-primary-600 underline hover:opacity-50;
}

.rich-text h1 {
  @apply mb-4 text-h1 font-bold text-gray-600;
}

.rich-text h2 {
  @apply mb-4 text-h2 font-bold text-gray-600;
}

.rich-text h3 {
  @apply mb-4 text-h3 font-bold text-gray-600;
}

.rich-text h4 {
  @apply mb-4 text-h4 font-bold text-gray-600;
}

.rich-text h5 {
  @apply mb-4 text-h5 font-bold text-gray-600;
}

.rich-text h6 {
  @apply mb-4 text-body-5 font-semibold text-gray-600;
}

.gm-style-iw-d {
  @apply !overflow-hidden;
}

.gm-style-iw {
  @apply !p-0;
}

@layer base {
  body {
    @apply antialiased;
  }

  .progress-ring__circle {
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
}

@layer utilities {
  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }

  .skeleton {
    @apply pointer-events-none relative min-h-[1em] overflow-hidden before:absolute before:inset-0 before:z-10 before:bg-gray-100 after:absolute after:inset-0 after:z-10 after:-translate-x-full after:animate-[shimmer_1.125s_ease_infinite] after:bg-skeleton;
  }

  .animate-in {
    animation: fadeIn 0.2s forwards;
  }
  .animate-out {
    animation: fadeOut 0.2s forwards;
  }
  .fade-in-0 {
    opacity: 0;
    animation: fadeIn 0.2s forwards;
  }
  .fade-out-0 {
    opacity: 1;
    animation: fadeOut 0.2s forwards;
  }

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    to {
      opacity: 0;
    }
  }
  @keyframes zoomIn {
    to {
      transform: scale(1);
    }
  }
  @keyframes zoomOut {
    to {
      transform: scale(0.95);
    }
  }
}
